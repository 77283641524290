import Modal from "./Modal";
import ScrollReveal from "./ScrollReveal";

export default function Project({project}) {
    return (
        // <div className="relative w-full h-64 rounded-lg overflow-hidden">
        //     <img
        //         className="w-full h-full object-cover absolute inset-0"
        //         src={project.image}
        //     />
        //     <div className="absolute inset-0 bg-gradient-to-t from-black opacity-50 hover:opacity-75">
        //         <div className="flex justify-center h-full text-white">
        //             <div className="">
        //                 <h3 className="text-xl font-bold">{project.name}</h3>
        //                 <p className="text-base">{project.shortDesc}</p>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <ScrollReveal>
            <div className={`relative bg-gray-300 h-[450px] rounded-3xl w-[375px] sm:w-[500px] overflow-hidden`}>
                <img className={'object-cover h-full w-full relative z-0 brightness-[30%]'} src={project.image}/>
                <div className={'top-0 p-4 z-40 absolute inset-0 flex flex-col justify-between'}>
                    <div className={''}>
                        <h1 className={'text-white font-black text-3xl '}>{project.name}</h1>
                        <h2 className={'text-gray-300'}>{project.shortDesc}</h2>
                    </div>

                    <div className={'text-white flex flex-col'}>
                        <p className={'text-white w-1/2'}>
                            {project.description}
                        </p>
                        <div className={"flex flex-wrap gap-2 py-3"}>
                            {
                                project.tags.map((tag, index) => {
                                    return <span key={index}
                                                 className={'bg-violet-900 bg-opacity-60 rounded-xl py-1 px-2 text-white'}>
                            {tag}
                        </span>
                                })
                            }
                        </div>
                        <div className={'flex gap-3'}>
                            <a className={'p-3 bg-white border-2 border-white text-black font-bold rounded-2xl flex items-center justify-between transition-all w-full hover:shadow-inner'}
                               href={project.url}><span>Learn more</span><i
                                className='bx bx-right-arrow-alt text-2xl font-black'></i></a>
                            {
                                project.git ?
                                    <a className={'px-3 py-2.5 text-3xl border-2 rounded-2xl hover:bg-white transition-all hover:text-black'}
                                       href={project.git}><i className='bx bxl-github align-middle'></i></a>
                                    :
                                    <a className={'px-3 py-2.5 text-3xl border-2 rounded-2xl hover:bg-white transition-all hover:text-black'}
                                       href={project.be}><i className='bx bxl-behance align-middle'></i></a>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </ScrollReveal>
    )
}
